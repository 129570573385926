import React, { useState } from "react";
import SemiBoldText from "../components/common/SemiBoldText";
import {
  AFFILIATE_DISCOUNT_PERCENTAGE,
  APP_URL_BY_MODULE,
  HEADER_LEVEL,
  MODULE_NAME,
  PARTNERS,
  SANA_SIGNUP_CODE,
  UPRISE_APP_SIGNUP_URL,
} from "../utils/commonConst";
import {
  encodeUrl,
  getAppUrlByModule,
  getModuleUrl,
} from "../utils/commonUtil";
import { Colors } from "../styles/styleConstants";
import PremiumTag from "../components/premium/PremiumTag";
import Tag from "../components/common/Tag";
import FreeTag from "../components/premium/FreeTag";
import BodyText from "../components/common/BodyText";
import HeaderText from "../components/common/HeaderText";
import UpriseEmailLink from "../components/common/UpriseEmailLink";
import HelperText from "../components/common/HelperText";
import {
  NERDWALLET_COACH_HELLO_EMAIL,
  NERDWALLET_COACH_SMB_HELLO_EMAIL,
} from "./commonData";

export const ValueProp = {
  Content1: {
    primary: "Know where to put each dollar so that it works harder for you.",
    secondary:
      "Together, we’ll look at your entire money life and figure out where your money should go to accomplish your life goals. We crunch the numbers so you don’t have to.",
    imagePath: "v2.0/homepage1-allocation.png",
    imageWidth: "100%",
    moduleName: "Money allocation",
    getStartedButtonLabel: "Get allocation advice",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.MONEY_ALLOCATION),
    learnMoreText: "Learn more about our allocation package",
    learnMoreLink: getModuleUrl(MODULE_NAME.MONEY_ALLOCATION),
    bgColor: "#e5fefc",
  },
  Content2: {
    primary: "Get to financial independence smarter and faster.",
    secondary:
      "Maximize your lifetime tax savings by choosing the right retirement accounts for you. IRAs, 401(k)s, Roth vs Traditional, HSAs, Mega backdoors. We cover it all here.",
    imagePath: "v2.0/homepage2-retirement.png",
    imageWidth: "100%",
    moduleName: "Retirement",
    getStartedButtonLabel: "Get retirement advice",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.RETIREMENT),
    learnMoreText: "Learn more about our retirement package",
    learnMoreLink: getModuleUrl(MODULE_NAME.RETIREMENT),
    bgColor: "#fde4d1",
  },

  Content3: {
    primary: "Know exactly what to do with your stock options.",
    secondary:
      "Whether to early exercise, how much to exercise, WTH an 83(b) is and more. We help you navigate it all.",
    imagePath: "v2.0/homepage3-companystock.png",
    imageWidth: "100%",
    moduleName: "Company equity",
    getStartedButtonLabel: "Get company stock advice",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.COMPANY_EQUITY),
    learnMoreText: "Learn more about our company stock package",
    learnMoreLink: getModuleUrl(MODULE_NAME.COMPANY_EQUITY),
    bgColor: "#ffdb3c4d",
  },

  Content4: {
    primary: "Build your investing strategy with an expert.",
    secondary:
      "Crypto? Real estate? Never worry if you’re invested in the right things or if your investments are in the right accounts to minimize taxes.",
    imagePath: "v2.0/homepage4-portfolio.png",
    imageWidth: "100%",
    moduleName: "Porfolio advice",
    getStartedButtonLabel: "Get portfolio advice",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.PORTFOLIO_ADVICE),
    learnMoreText: "Learn more about our portfolio advice package",
    learnMoreLink: getModuleUrl(MODULE_NAME.PORTFOLIO_ADVICE),
    bgColor: "#ebefff",
  },

  Content5: {
    primary: "Get all the money hacks, personalized for you.",
    secondary:
      "Want it all? Never worry about money again by letting us continually optimize your whole financial life (text your advisor any time!) for less than the cost of your Netflix subscription.",
    imagePath: "v2.0/homepage5-allaccess.png",
    imageWidth: "100%",
    moduleName: "All-access",
    getStartedButtonLabel: "Get all-access",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.ALL_ACCESS),
    learnMoreText: "Learn more about all-access",
    learnMoreLink: getModuleUrl(MODULE_NAME.ALL_ACCESS),
    bgColor: "#e5fefc",
  },
};

export const MoneyAllocationValueProp = {
  Content1: {
    primary:
      "All of your allocation questions answered by actual money experts.",
    secondary:
      "More tailored answers in less time than parsing through the internet. And no, we don’t think your friends/Reddit are a good alternative to the Google either.",
    imagePath: "v2.0/allocation1.png",
    imageWidth: "100%",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.MONEY_ALLOCATION),
  },
  Content2: {
    primary:
      "March confidently toward your goals with a plan for every dollar.",
    secondary:
      "Stop feeling guilty about every boba purchase :P Live your life knowing that you're making all the right money moves.",
    imagePath: "v2.0/allocation2.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.MONEY_ALLOCATION),
  },

  Content3: {
    primary:
      "We get to know you so every recommendation is fully personalized.",
    secondary: (
      <div>
        Want to retire as soon as humanly possible? Tiny home your dream? <br />
        <br />
        We make sure to understand you first before making any recommendations
        :)
      </div>
    ),
    imagePath: "v2.0/allocation3.gif",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.MONEY_ALLOCATION),
  },

  Content4: {
    primary:
      "We’ll revise together until you’re confident in your action plan.",
    secondary: "",
    imagePath: "v2.0/allocation4.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.MONEY_ALLOCATION),
  },
};

export const CoreValueProp = {
  Content1: {
    primary: "All your money questions answered by an expert, all year long.",
    secondary:
      "More tailored answers in less time than parsing through the internet. And no, we don’t think your friends/Reddit are a good alternative to the Google either.",
    imagePath: "v2.0/allocation1.png",
    imageWidth: "100%",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.CORE),
  },
  Content2: {
    primary:
      "March confidently toward your goals with a plan for every dollar.",
    secondary:
      "Stop feeling guilty about every boba purchase :P Live your life knowing that you're making all the right money moves.",
    imagePath: "v2.0/allocation2.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.CORE),
  },

  Content3: {
    primary:
      "We get to know you so every recommendation is fully personalized.",
    secondary: (
      <div>
        Want to retire as soon as humanly possible? Tiny home your dream? <br />
        <br />
        We make sure to understand you first before making any recommendations
        :)
      </div>
    ),
    imagePath: "v2.0/allocation3.gif",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.CORE),
  },

  Content4: {
    primary:
      "We’ll revise together until you’re confident in your action plan.",
    secondary: "",
    imagePath: "v2.0/allocation4.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.CORE),
  },
};

export const RetirementValueProp = {
  Content1: {
    primary:
      "All of your retirement questions answered by actual money experts.",
    secondary:
      "More tailored answers in less time than parsing through the internet. And no, we don’t think your friends/Reddit are a good alternative to the Google either.",
    imagePath: "v2.0/retirement1.png",
    imageWidth: "100%",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.RETIREMENT),
  },
  Content2: {
    primary:
      "A retirement savings plan as unique as your daydreams about winning the lottery.",
    secondary: (
      <div>
        Want to travel a lot in retirement? Live out of a boat abroad? Or do you
        just want to get to financial independence as quick as possible?
        <br />
        <br />
        We want all the details so we really understand you before making any
        recommendations :)
      </div>
    ),
    imagePath: "v2.0/retirement2.gif",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.RETIREMENT),
  },

  Content3: {
    primary:
      "We’ll revise together until you’re confident in your action plan.",
    secondary: "",
    imagePath: "v2.0/retirement3.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.RETIREMENT),
  },
};

export const AllAccessValueProp = {
  Content1: {
    primary: "All your money questions answered by an expert, all year long.",
    secondary:
      "Spot-on answers backed by data in less time than parsing through the internet. And no, we don’t think your friends/Reddit are a good alternative to the Google either.",
    imagePath: "v3.0/all-access1-v3.png",
    imageWidth: "100%",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.ALL_ACCESS),
  },
  Content2: {
    primary: "Here’s what you should invest in.",
    secondary:
      "Crypto? Real estate? Never worry if you’re invested in the right things or if your investments are in the right accounts to minimize taxes.",
    imagePath: "v2.0/homepage4-portfolio.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.ALL_ACCESS),
  },
  Content3: {
    primary:
      "March confidently toward your goals with a plan for every dollar.",
    secondary:
      "Stop feeling guilty about every boba purchase :P Live your life knowing that you're making all the right money moves.",
    imagePath: "v2.0/allocation2.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.ALL_ACCESS),
  },
  Content4: {
    primary:
      "We get to know you so every recommendation is fully personalized.",
    secondary: `Curious wth ESG stands for? Fine art and angel investing? Or just want to set and forget your investments?
      We’ll get to know you and make sure your portfolio is diversified and invested in the right things and that everything's in the right accounts.`,
    imagePath: "v3.0/all-access5.jpg",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.ALL_ACCESS),
  },
  Content5: {
    primary:
      "We’ll revise together until you’re confident in your action plan.",
    secondary: ``,
    imagePath: "v2.0/equity4-iterate.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.ALL_ACCESS),
  },
};

export const PortfolioAdviceProp = {
  Content1: {
    primary: "All of your questions answered by actual money experts.",
    secondary:
      "Spot-on answers backed by data in less time than parsing through the internet. And no, we don’t think your friends/Reddit are a good alternative to the Google either.",
    imagePath: "v2.0/portfolio1-questions.png",
    imageWidth: "100%",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.PORTFOLIO_ADVICE),
  },
  Content3: {
    primary: "Here’s what you should invest in.",
    secondary:
      "Crypto? Real estate? Never worry if you’re invested in the right things or if your investments are in the right accounts to minimize taxes.",
    imagePath: "v2.0/portfolio3-investin.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.PORTFOLIO_ADVICE),
  },
  Content4: {
    primary:
      "We’ll revise together until you’re confident in your action plan.",
    secondary: "",
    imagePath: "v2.0/portfolio4-iterate.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.PORTFOLIO_ADVICE),
  },
};

export const PortfolioRSUProp = {
  Content1: {
    ...PortfolioAdviceProp.Content1,
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.RSU),
  },
  Content2: {
    primary:
      "Know exactly how your RSUs fit in and how to minimize taxes and risk.",
    secondary: (
      <div>
        Hold or sell? How much? RSUs or ESPP first?
        <br />
        <br />
        We’ll tell you exactly what to do to make sure you're not leaving money
        on the table or getting hit with a huge tax bill.
      </div>
    ),
    imagePath: "v2.0/portfolio2-rsus.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.RSU),
  },
  Content3: {
    ...PortfolioAdviceProp.Content3,
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.RSU),
  },
  Content4: {
    ...PortfolioAdviceProp.Content4,
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.RSU),
  },
};

export const CompanyEquityProp = {
  Content1: {
    primary: "All of your questions answered by actual money experts.",
    secondary:
      "Spot-on answers backed by data in less time than parsing through the internet. And no, we don’t think your friends/Reddit are a good alternative to the Google either.",
    imagePath: "v2.0/equity1-questions.png",
    imageWidth: "100%",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.COMPANY_EQUITY),
  },
  Content2: {
    primary: "When and how much to exercise, and the tax implications.",
    secondary: (
      <div>
        Should you early exercise? How much will it really cost you?
        <br />
        <br />
        We’ll tell you exactly how to evaluate and manage your options so you
        can be confident you’re keeping as much of your money as possible.
      </div>
    ),
    imagePath: "v2.0/equity2-taxes.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.COMPANY_EQUITY),
  },
  Content3: {
    primary: "What to do when your company IPOs or gets acquired.",
    secondary: (
      <div>
        Your company has a successful exit 🚀🚀🚀
        <br />
        <br />
        We’ll help you figure out when to sell, how much and what to do with
        your hard-earned money.
      </div>
    ),
    imagePath: "v2.0/equity3-advice.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.COMPANY_EQUITY),
  },
  Content4: {
    primary:
      "We’ll revise together until you’re confident in your action plan.",
    secondary: "",
    imagePath: "v2.0/equity4-iterate.png",
    imageWidth: "100%",
    moduleName: "",
    getStartedButtonLabel: "Get started",
    getStartedButtonLink: getAppUrlByModule(MODULE_NAME.COMPANY_EQUITY),
  },
};

export const TopicsCoveredContent = {
  retirement: {
    topicsText: (
      <div>
        How much to really save for <SemiBoldText>retirement</SemiBoldText> and
        how
      </div>
    ),
    imagePath: "home/retirement-ic.png",
    imageWidth: "48px",
    imageHeight: "48px",
  },
  saving: {
    topicsText: (
      <div>
        Saving for <SemiBoldText>big goals</SemiBoldText> like grad school or a
        house
      </div>
    ),
    imagePath: "home/savings-ic.png",
    imageWidth: "48px",
    imageHeight: "48px",
  },
  investing: {
    topicsText: (
      <div>
        <SemiBoldText>Allocating your money:</SemiBoldText> debt vs investing vs
        retirement
      </div>
    ),
    imagePath: "home/invest-ic.png",
    imageWidth: "48px",
    imageHeight: "48px",
  },
  benefit: {
    topicsText: (
      <div>
        Maximizing <SemiBoldText>employer benefits</SemiBoldText> to the fullest
      </div>
    ),
    imagePath: "home/benefits-ic.png",
    imageWidth: "48px",
    imageHeight: "48px",
  },
  creditCard: {
    topicsText: (
      <div>
        Credit cards with the <SemiBoldText>best rewards</SemiBoldText> for
        *you*
      </div>
    ),
    imagePath: "home/creditcard-ic.png",
    imageWidth: "48px",
    imageHeight: "48px",
  },
  other: {
    topicsText: (
      <div>
        <SemiBoldText>And dozens more</SemiBoldText> with a focus on your
        situation
      </div>
    ),
    imagePath: "home/taxes-ic.png",
    imageWidth: "48px",
    imageHeight: "48px",
  },
};

export const PartnerTopicsData = {
  goal_budgeting: {
    title: "Goals and budgeting",
    details_bullets: [
      "Money allocation",
      "Renting vs buying",
      "Buying / owning a home",
      "Kids' college",
    ],
    image: {
      path: "partner/img-GoalsBudgeting@2x.png",
      width: "174px",
      height: "150px",
    },
  },
  tax_adv_retirement_accounts: {
    title: "Tax-advantaged retirement accounts",
    details_bullets: [
      "Employer sponsored accounts (401k, HSA)",
      "Non-employer retirement accounts (IRA, Solo 401k, HSA)",
    ],
    image: {
      path: "partner/img-retirement@2x.png",
      width: "205px",
      height: "150px",
    },
  },
  investments: {
    title: "Investments",
    details_bullets: [
      "Investment portfolio allocation",
      "Stock options",
      "RSUs",
      "ESPP",
    ],
    image: {
      path: "partner/img-investments@2x.png",
      width: "220px",
      height: "150px",
    },
  },
  foundation: {
    title: "The foundations",
    details_bullets: [
      "Building credit",
      "Paying down debt",
      "Emergency savings",
    ],
    image: {
      path: "partner/img-foundations@2x.png",
      width: "199px",
      height: "150px",
    },
  },
  managing_money: {
    title: "Managing money at every stage",
    details_bullets: [
      "Side income / self-employment",
      "Salary benchmarking",
      "Estate planning",
      "Getting ready for retirement",
    ],
    image: {
      path: "partner/img-everystage@2x.png",
      width: "227px",
      height: "150px",
    },
  },
  optimizing_accounts: {
    title: "Optimizing accounts",
    details_bullets: [
      "Bank accounts with the best interest rates",
      "Credit cards with the best rewards",
    ],
    image: {
      path: "partner/img-optimizingaccounts@2x.png",
      width: "300px",
      height: "150px",
    },
  },
};

export const Workflow = {
  Content1: {
    primary: "Tell us about yourself",
    secondary:
      "Goals, what do you care about, where do you live, who do you work for? All these things matter when it comes to optimizing your unique situation.",
    imagePath: "home/illustration_7.png",
    imageWidth: "70%",
  },
  Content2: {
    primary: "We connect you to the best options for you",
    secondary:
      "We route you to all the best financial options out there (credit cards, high yield savings accounts, loans, benefits) and tell you how to take full advantage of them - and, yes, there's actually a human looking at your situation.",
    imagePath: "home/illustration_5.png",
    imageWidth: "70%",
  },
  Content3: {
    primary: "Stay on track",
    secondary:
      "We'll continuously monitor your finances for optimization opportunities, and adjust with you as your life changes.",
    imagePath: "home/illustration_6.png",
    imageWidth: "70%",
  },
};

// How it works workflow
export const HiwWF = {
  title: "How Uprise works",
  steps: [
    {
      iconPath: "v2.0/heart-yellow.png",
      iconWidth: "48px",
      iconHeight: "48px",
      title: "Fully personalized",
      desc: "Everything we do starts with understanding you first so that our recommendations actually resonate.",
    },
    {
      iconPath: "v2.0/human-face-yellow.png",
      iconWidth: "48px",
      iconHeight: "48px",
      title: "Powered by human experts + tech",
      desc: "Think of us like a doctor backed by a massive database of all the symptoms and cures, but for your money.",
    },
    {
      iconPath: "v2.0/hand-hold-yellow.png",
      iconWidth: "48px",
      iconHeight: "48px",
      title: "We’ll keep going till it’s right",
      desc: "Ask us questions or hit us with your concerns. We’ll adjust your plan together until it’s perfect for your unique situation.",
    },
  ],
};

export const HiwWF_V2 = {
  title: "How we’ll work together",
  steps: [
    {
      iconPath: "v2.0/heart-yellow.png",
      iconWidth: "48px",
      iconHeight: "48px",
      title: "Get to know you quiz",
      desc: "Goals, what do you care about, where do you live, who do you work for? All these things matter when it comes to optimizing your unique situation.",
    },
    {
      iconPath: "v2.0/howitworks2b.png",
      iconWidth: "48px",
      iconHeight: "48px",
      title: "First draft of investing plan",
      desc: "We’ll create an action plan for where invested dollar makes most sense based on your interests, risk tolerance and goals.",
    },
    {
      iconPath: "v2.0/hand-hold-yellow.png",
      iconWidth: "48px",
      iconHeight: "48px",
      title: "Iterate together",
      desc: "Ask us questions or hit us with your concerns. We’ll adjust your plan together until it’s perfect for your unique situation.",
    },
  ],
};

export const NerdWalletTrustedBy = {
  title: "Uprise is trusted by employees at",
  images: [
    [
      { path: "nerdwallet/google_icon.png", width: "150px", height: "42px" },
      {
        path: "nerdwallet/uber_icon.png",
        width: "150px",
        height: "32px",
      },
      { path: "nerdwallet/amazon_icon.png", width: "150px", height: "38px" },
      {
        path: "nerdwallet/walmart_icon.png",
        width: "175px",
        height: "36px",
      },
      {
        path: "nerdwallet/openai_icon.png",
        width: "150px",
        height: "35px",
      },
    ],
  ],
};

export const TrustedBy = {
  title: "Trusted by employees at",
  images: [
    [
      { path: "home/trusted_by/google_v1.png", width: "77px", height: "32px" },
      {
        path: "home/trusted_by/microsoft_v1.png",
        width: "114px",
        height: "32px",
      },
      { path: "home/trusted_by/tesla_v1.png", width: "102px", height: "32px" },
      { path: "home/trusted_by/carta_v1.png", width: "64px", height: "32px" },
      {
        path: "home/trusted_by/mckinsey_v1.png",
        width: "102.77px",
        height: "40px",
      },
      {
        path: "home/trusted_by/harvard_v1.png",
        width: "120.79px",
        height: "40px",
      },
      // { path: "home/trusted_by/bessemer.png", width: "175px", height: "100px" },
    ],
  ],
};

export const BackedBy = {
  title: "Backed by world-class fintech execs and founders from",
  sectionName: "BackedBy",
  images: [
    [
      {
        path: "partner/robinhood@2x.png",
        width: "160px",
        height: "30px",
      },
      { path: "partner/stripe@2x.png", width: "140px", height: "32px" },
      {
        path: "partner/cash-app@2x.png",
        width: "140px",
        height: "32px",
      },
      { path: "partner/sofi@2x.png", width: "112px", height: "32px" },
      {
        path: "partner/clarity-money@2x.png",
        width: "163px",
        height: "32px",
      },
      { path: "partner/gusto@2x.png", width: "100px", height: "32px" },
    ],
  ],
};

export const BuiltBy = {
  title: "From the people who built ",
  sectionName: "BuiltBy",
  images: [
    [
      { path: "home/built_by/robinhood.png", width: "170px" },
      { path: "home/built_by/wise.png", width: "100px" },
      { path: "home/built_by/justworks.svg", width: "150px" },
      { path: "home/built_by/facebook.png", width: "140px" },
    ],
  ],
};

export const PressReleaseData = {
  sectionName: "Press Release",
  images: [
    [
      {
        path: "home/press_release/img-tech-crunch.png",
        width: "147px",
        height: "40px",
        link: "https://techcrunch.com/2022/07/11/uprise-1-4m-family-office-gen-z/",
      },
      {
        path: "home/press_release/img-biz-insider.png",
        width: "114px",
        height: "40px",
        link: "https://www.businessinsider.com/gen-z-personal-finance-uprise-pitch-deck-fintech-startup-2022-7",
      },
      {
        path: "home/press_release/img-product-hunt.png",
        width: "153px",
        height: "60px",
        link: "https://www.producthunt.com/products/uprise-2#uprise-2",
      },
      {
        path: "home/press_release/img-golden-kitty.png",
        width: "150px",
        height: "48px",
        link: "https://www.producthunt.com/products/uprise-2#uprise-2",
      },
    ],
  ],
};

export const FinancialInstitution = {
  sectionName: "Financial Institution",
  title: "Trusted by world-class financial institutions",
  images: [
    [
      {
        path: "partner/ic-partnerlogo1@2x.png",
        width: "150px",
        height: "40px",
        link: "",
      },
      {
        path: "partner/ic-partnerlogo2@2x.png",
        width: "140px",
        height: "40px",
        link: "",
      },
      {
        path: "partner/ic-partnerlogo3@2x.png",
        width: "90px",
        height: "60px",
        link: "",
      },
      {
        path: "partner/ic-partnerlogo4@2x.png",
        width: "140px",
        height: "48px",
        link: "",
      },
      {
        path: "partner/ic-partnerlogo6@2x.png",
        width: "120px",
        height: "40px",
        link: "",
      },
      {
        path: "partner/ic-partnerlogo7@2x.png",
        width: "116px",
        height: "34px",
        link: "",
      },
      {
        path: "partner/ic-partnerlogo8@2x.png",
        width: "102px",
        height: "45px",
        link: "",
      },
    ],
  ],
};

export const FaqData = [
  {
    question: "How are you able to keep prices so low?",
    questionKey: "cost",
    id: "faq-cost",
    answer: {
      secondary:
        "In short, technology! We’re able to offer expert financial advising at a price that’s 10x more affordable by combining Certified Financial Planners with our advanced database of financial rules and products. Think of us like a doctor backed by a massive database of all the symptoms and cures, but for your money :)",
    },
    isExpanded: false,
  },
  {
    question: "How does the money back guarantee work?",
    questionKey: "money-back",
    id: "faq-money-back",
    answer: {
      secondary: (
        <div>
          It’s really simple. If you’re not happy with what you get from us, you
          can get a full refund by emailing us within 45 days at{" "}
          <UpriseEmailLink
            style={{ display: "inline" }}
            label="hello@uprise.us"
          />
          .
        </div>
      ),
    },
    isExpanded: false,
  },
  // {
  //   question: "How much does this cost?",
  //   questionKey: "cost",
  //   id: "faq-cost",
  //   answer: {
  //     primary: "It's completely free. No obligations, no hidden fees.",
  //     secondary: (
  //       <div>
  //         <div style={{ marginBottom: "12px" }}>
  //           Today, our free product is supported by tips! Tipping is completely
  //           optional and supports keeping our product free.
  //         </div>
  //         <div style={{ marginBottom: "12px" }}>
  //           We also have a premium tier that costs $199/year (less than $1 a
  //           day!). With Uprise Premium, customers get access to more complex
  //           topics, like investments, real estate, and stock options, and can
  //           message their advisors anytime.
  //         </div>
  //         <div style={{ marginBottom: "12px" }}>
  //           In the future, we intend to also sustain our business by getting
  //           paid when customers sign up for financial products we recommend. All
  //           that said, how we make money will never impact our advice to you.
  //           Our recommendations will be independent and will reflect what we
  //           think is best for you, period, for as long as we’re around.
  //         </div>
  //       </div>
  //     ),
  //   },
  //   isExpanded: false,
  // },
  {
    question: "Where does the $1.5M net worth number come from?",
    questionKey: "netWorth",
    id: "faq-netWorth",
    answer: {
      primary: "",
      secondary: (
        <div>
          <div style={{ marginBottom: "12px" }}>
            Each Uprise financial plan provides custom recommendations to help
            customers reach their goals, most of which have a measurable
            financial impact. Uprise calculates the total net worth impact from
            these recommendations.
          </div>
          <div style={{ marginBottom: "12px" }}>
            Uprise values net worth created for customers by comparing Uprise
            recommendations to keeping cash in a savings account. The value
            created primarily comes from: (1) tax advantages, (2) free money
            from employer benefits, (3) investment compounding, (4) increased
            earnings, and (5) fee savings.
          </div>
          <div style={{ marginBottom: "12px" }}>
            We assume recommendations are implemented for up to 5 years. Uprise
            assumes market performance in line with long-term US equity returns,
            as measured by the S&P 500.
          </div>
        </div>
      ),
    },
    isExpanded: false,
  },
  {
    question: "I live outside the U.S. Can I still qualify for Uprise?",
    questionKey: "outsideUs",
    id: "faq-outsideUs",
    answer: {
      secondary: (
        <div>
          <div>
            Though we’re only able to serve people within the U.S. at the
            moment, our long-term goal is to make Uprise available to customers
            across the globe.
          </div>
          <br />
          <div>
            In the meantime, we’d still love to have you as a member of the
            Uprise community on YouTube. For weekly money tips, check out our
            videos{" "}
            <a href="https://www.youtube.com/channel/UC_9O8oJREc1ldakUYn2r2OQ">
              here
            </a>
          </div>
        </div>
      ),
    },
    isExpanded: false,
  },
];
// customerPhoto: "home/ethan-photo.png",

export const FeedbackData = [
  {
    feedbackText: (
      <div>
        In shows like Billions, really wealthy people have advisors following
        them around, helping them{" "}
        <SemiBoldText>optimize every single decision</SemiBoldText> - that's
        what this feels like.{" "}
        <SemiBoldText>Elite guard rails and life hacks</SemiBoldText>.
      </div>
    ),
    feedbackBy: (
      <div>
        <SemiBoldText>Ethan</SemiBoldText> Product manager
      </div>
    ),
  },
  {
    feedbackText: (
      <div>
        <SemiBoldText>I swear my jaw dropped</SemiBoldText> every page I went
        to. I was{" "}
        <SemiBoldText>
          truly amazed at how personalized the plan was
        </SemiBoldText>
        , down to even using my zip code to estimate the cost of certain
        expenses. It was so incredibly detailed.
      </div>
    ),
    feedbackBy: (
      <div>
        <SemiBoldText>Grace</SemiBoldText> Content writer
      </div>
    ),
  },
  {
    feedbackText: (
      <div>
        This service is great. Minimal effort, but I feel like the future just
        opened up for me. I have a{" "}
        <SemiBoldText>
          clear idea of where my money needs to go and what it will be doing
        </SemiBoldText>
        . I hope more people find and use Uprise!
      </div>
    ),
    feedbackBy: (
      <div>
        <SemiBoldText>Josh</SemiBoldText> Software engineer
      </div>
    ),
  },
  {
    feedbackText: (
      <div>
        Uprise really helped me make some personal finance decisions involving
        my 401(k), investment accounts -- even credit cards.{" "}
        <SemiBoldText>Not to mention that it was all free!</SemiBoldText>
      </div>
    ),
    feedbackBy: (
      <div>
        <SemiBoldText>Erick</SemiBoldText> Portfolio manager
      </div>
    ),
  },
];

export const FeedbackDataV2 = [
  {
    feedbackText: (
      <div>
        <SemiBoldText>
          Wow, this really was personalized for us. Incredible.
        </SemiBoldText>
      </div>
    ),
    feedbackBy: (
      <div>
        <SemiBoldText>Tanner & Jessicah</SemiBoldText> Biz dev
      </div>
    ),
  },
  {
    feedbackText: (
      <div>
        <SemiBoldText>
          I’ve never felt more on track regarding finances.
        </SemiBoldText>
      </div>
    ),
    feedbackBy: (
      <div>
        <SemiBoldText>Danielle </SemiBoldText> CX manager
      </div>
    ),
  },
  {
    feedbackText: (
      <div>
        <SemiBoldText>
          Elite guard rails and life hacks. That's what this feels like.
        </SemiBoldText>
      </div>
    ),
    feedbackBy: (
      <div>
        <SemiBoldText>Ethan</SemiBoldText> Product manager
      </div>
    ),
  },
  {
    feedbackText: (
      <div>
        <SemiBoldText>
          I swear my jaw dropped every page I went to. I was truly amazed.
        </SemiBoldText>
      </div>
    ),
    feedbackBy: (
      <div>
        <SemiBoldText>Grace</SemiBoldText> Content creator
      </div>
    ),
  },
];

export const TeamProfiles = [
  {
    name: "Jessica",
    photoUrl: "home/team/j1.png",
    title: "Co-founder / CEO",
    emoji: "🍜",
    oneLiner: (
      <div>
        <div>1. Drink boba.</div>
        <div>2 Wear sneakers.</div>
        <div>3 Change the world.</div>{" "}
      </div>
    ),
    linkedInUrl: "https://www.linkedin.com/in/jessicachenriolfi/",
    companyImages: [
      {
        path: "home/built_by/robinhood-grey.png",
        width: "96px",
        height: "24px",
      },
      { path: "home/built_by/wise-grey.png", width: "96px", height: "24px" },
      { path: "home/built_by/earnin.png", width: "96px", height: "24px" },
    ],
  },
  {
    name: "Chris",
    photoUrl: "home/team/c2.png",
    title: "Co-founder / Growth",
    emoji: "🌱",
    oneLiner:
      "Ex: employee #2 at Justworks and uncertain how anyone ever made financial decisions before Uprise.",
    linkedInUrl: "https://www.linkedin.com/in/cgoodmac/",
    companyImages: [
      {
        path: "home/built_by/justworks-grey.png",
        width: "316px",
        height: "24px",
      },
    ],
  },
  {
    name: "Nantha",
    photoUrl: "home/team/n3.png",
    title: "Co-founder / Engineering",
    emoji: "🧘🏽‍♂️",
    oneLiner: "Obsessed with building and capturing Uprise memories.",
    linkedInUrl: "https://www.linkedin.com/in/nanthakumar-muthusamy-67545120/",
    companyImages: [
      {
        path: "home/built_by/facebook-grey.png",
        width: "96px",
        height: "24px",
      },
      { path: "home/built_by/oracle.png", width: "96px", height: "24px" },
    ],
  },
  {
    name: "Lena",
    photoUrl: "home/team/l4.png",
    title: "Product design",
    emoji: "👑",
    oneLiner:
      "Personal finance nerd. Lover of a good spreadsheet and black t-shirts.",
    linkedInUrl: "https://www.linkedin.com/in/lenamalk/",
    companyImages: [
      { path: "home/built_by/bloomberg.png", width: "96px", height: "24px" },
      { path: "home/built_by/angi.png", width: "96px", height: "24px" },
      {
        path: "home/built_by/weight-watchers.png",
        width: "96px",
        height: "24px",
      },
    ],
  },
  {
    name: "Caroline, CFP®",
    photoUrl: "home/team/c5.png",
    title: "Financial planning",
    emoji: "🐕",
    oneLiner:
      "Animal advocate and firm believer that everyone needs a money (wo)man in their court.",
    linkedInUrl: "https://www.linkedin.com/in/carolinempetty/",
    companyImages: [
      {
        path: "home/built_by/raymond-james.png",
        width: "316px",
        height: "24px",
      },
    ],
  },
];

const ALL_ACCESS_PRICE_YEARLY = 289;
const ALL_ACCESS_PRICE_MONTHLY = Math.round(ALL_ACCESS_PRICE_YEARLY / 12);
const SINGLE_TOPIC_PRICE = 99;
const UPRISE_CORE_PRICE = 189;
const CLAIR_DISCOUNT = 189;

const withoutUprisePrice = {
  name: "Without Uprise",
  icon: {
    path: "v2.0/pricing-withoutuprise.png",
    width: "72px",
    height: "72px",
  },
  price: "$2,000+",
  desc: (
    <div>
      <HeaderText
        level={HEADER_LEVEL.BODY_COPY}
        style={{ color: `${Colors.neutralMedDark};` }}
      >
        For traditional financial planning from companies like Personal Capital,
        Morgan Stanley, and Merrill Lynch.
      </HeaderText>
      <HeaderText
        level={HEADER_LEVEL.LEVEL_4}
        style={{
          margin: "16px 0px",
          color: `${Colors.neutralMedDark}`,
        }}
      >
        or endless DIY-ing
      </HeaderText>
      <HeaderText
        level={HEADER_LEVEL.BODY_COPY}
        style={{ color: `${Colors.neutralMedDark};` }}
      >
        But TBH, the internet doesn’t get you like we do.
      </HeaderText>
    </div>
  ),
  isDisabled: true,
};

export const CorePricingTierData = [
  // {
  //   name: "One-time, one topic",
  //   iconPath: "v2.0/pricing-single.png",
  //   icon: {
  //     path: "v2.0/pricing-single.png",
  //     width: "72px",
  //     height: "72px",
  //   },
  //   price: `$${SINGLE_TOPIC_PRICE}`,
  //   priceTerm: "once",
  //   // desc: "Get all of your questions answered around one topic for less than the price of a night out.",
  //   actionButton: {
  //     label: "Get started",
  //     targetLink: UPRISE_APP_SIGNUP_URL,
  //   },
  //   plan: [
  //     {
  //       title: "One-time check-in & plan about:",
  //       planSub: [
  //         "Money allocation",
  //         "Retirement",
  //         "Company equity",
  //         "Portfolio advice",
  //       ],
  //     },
  //     {
  //       title: "Human advisor crafting your plan",
  //     },
  //     {
  //       title: "We’ll revise till it’s perfect for you",
  //     },
  //   ],
  // },
  {
    name: "Core",
    iconPath: "v2.0/pricing-single.png",
    icon: {
      path: "v2.0/pricing-single.png",
      width: "72px",
      height: "72px",
    },
    price: `$${Math.ceil(UPRISE_CORE_PRICE / 12)}`,
    priceTerm: "/ month billed annually",
    isPopular: true,
    // desc: "Get all of your questions answered around one topic for less than the price of a night out.",
    actionButton: {
      label: "Get started",
      targetLink: getAppUrlByModule(MODULE_NAME.CORE),
    },
    learnMoreLink: getModuleUrl(MODULE_NAME.CORE),
    plan: [
      {
        title: "Annual plan + regular check-ins",
      },
      {
        title: "Human advisor crafting your plan",
      },
      {
        title: "We’ll revise till it’s right for you",
      },
      {
        title: "Message your advisor all year",
      },
    ],
    yearly: `All for $${UPRISE_CORE_PRICE}/year`,
  },
  {
    name: "All-Access",
    icon: {
      path: "v2.0/pricing-allaccess.png",
      width: "72px",
      height: "72px",
    },
    price: `$${ALL_ACCESS_PRICE_MONTHLY}`,
    priceTerm: "/ month billed annually",
    // desc: "Optimize your entire financial life (and your partner’s) plus ask us anything all year long for less than a dollar a day.",
    actionButton: {
      label: "Get started",
      targetLink: getAppUrlByModule(MODULE_NAME.ALL_ACCESS),
    },
    learnMoreLink: getModuleUrl(MODULE_NAME.ALL_ACCESS),
    planHeader: (
      <span style={{ fontStyle: "italic" }}>Everything in Core plus:</span>
    ),
    plan: [
      {
        title: "Specific investment advice",
      },
      {
        title: "Specific company equity advice",
      },
    ],
    yearly: `All for just $${ALL_ACCESS_PRICE_YEARLY}/year`,
    availability: "Not available in IL, LA, MA, NH, OR, PA, TX yet.",
  },
  withoutUprisePrice,
];

export const PullPricingTierData = [
  {
    name: "Core",
    iconPath: "v2.0/pricing-single.png",
    icon: {
      path: "v2.0/pricing-single.png",
      width: "72px",
      height: "72px",
    },
    price: (
      <div>
        <span style={{ color: Colors.neutralMedDark }}>
          <strike>{`$${UPRISE_CORE_PRICE}`}</strike>
        </span>{" "}
        {`$${Math.round(
          UPRISE_CORE_PRICE -
            UPRISE_CORE_PRICE *
              (AFFILIATE_DISCOUNT_PERCENTAGE[PARTNERS.PULL] / 100)
        )}`}
        <span style={{ color: Colors.neutralMedDark }}> </span>
      </div>
    ),
    priceTerm: "/ year",
    // desc: "Get all of your questions answered around one topic for less than the price of a night out.",
    actionButton: {
      label: "Get started",
      targetLink: getAppUrlByModule(MODULE_NAME.CORE),
    },
    plan: [
      {
        title: "Annual plan + regular check-ins",
      },
      {
        title: "Human advisor crafting your plan",
      },
      {
        title: "We’ll revise till it’s right for you",
      },
      {
        title: "Message your advisor all year",
      },
    ],
    yearly: `All for $${(
      (UPRISE_CORE_PRICE -
        UPRISE_CORE_PRICE *
          (AFFILIATE_DISCOUNT_PERCENTAGE[PARTNERS.PULL] / 100)) /
      12
    ).toFixed(2)}/month billed annually`,
  },
  withoutUprisePrice,
];

export const PeachPricingTierData = [
  {
    name: "Core",
    iconPath: "v2.0/pricing-single.png",
    icon: {
      path: "v2.0/pricing-single.png",
      width: "72px",
      height: "72px",
    },
    price: (
      <div>
        <span style={{ color: Colors.neutralMedDark }}>
          <strike>{`$${UPRISE_CORE_PRICE}`}</strike>
        </span>{" "}
        {`$${Math.round(
          UPRISE_CORE_PRICE -
            UPRISE_CORE_PRICE *
              (AFFILIATE_DISCOUNT_PERCENTAGE[PARTNERS.PEACH] / 100)
        )}`}
        <span style={{ color: Colors.neutralMedDark }}> </span>
      </div>
    ),
    priceTerm: "/ year",
    // desc: "Get all of your questions answered around one topic for less than the price of a night out.",
    actionButton: {
      label: "Get started",
      targetLink: getAppUrlByModule(MODULE_NAME.CORE),
    },
    plan: [
      {
        title: "Annual plan + regular check-ins",
      },
      {
        title: "Human advisor crafting your plan",
      },
      {
        title: "We’ll revise till it’s right for you",
      },
      {
        title: "Message your advisor all year",
      },
    ],
    yearly: `All for $${(
      (UPRISE_CORE_PRICE -
        UPRISE_CORE_PRICE *
          (AFFILIATE_DISCOUNT_PERCENTAGE[PARTNERS.PEACH] / 100)) /
      12
    ).toFixed(2)}/month billed annually`,
  },
  withoutUprisePrice,
];

export const ClairPricingTierData = [
  {
    name: "Core",
    iconPath: "v2.0/pricing-single.png",
    icon: {
      path: "v2.0/pricing-single.png",
      width: "72px",
      height: "72px",
    },
    price: (
      <div>
        <span style={{ color: Colors.neutralMedDark }}>
          <strike>{`$${UPRISE_CORE_PRICE}`}</strike>
        </span>{" "}
        {`$${UPRISE_CORE_PRICE - CLAIR_DISCOUNT}`}
        <HeaderText level={HEADER_LEVEL.BODY_COPY} inline>
          <SemiBoldText>{" for 30 days"}</SemiBoldText>
        </HeaderText>
        {/* <span style={{ color: Colors.neutralMedDark }}>!!</span> */}
      </div>
    ),
    priceTerm: "",
    // desc: "Get all of your questions answered around one topic for less than the price of a night out.",
    actionButton: {
      label: "Get started",
      targetLink: getAppUrlByModule(MODULE_NAME.CORE),
    },
    plan: [
      {
        title: "Annual plan + regular check-ins",
      },
      {
        title: "Human advisor crafting your plan",
      },
      {
        title: "We’ll revise till it’s right for you",
      },
      {
        title: "Message your advisor all year",
      },
    ],
  },
  withoutUprisePrice,
];

export const SanaPricingTierData = [
  {
    name: "What you get",
    tag: <FreeTag label="$1 TRIAL" style={{ width: "86px" }} />,
    actionButton: {
      label: "Get started",
      targetLink: getAppUrlByModule(MODULE_NAME.CORE),
    },
    tierBenefits: {
      benefitTitle: "",
      benefitInfos: [
        {
          benefitIcon: "common/tick_grey.png",
          benefitText: "Comprehensive review of your finances",
        },
        {
          benefitIcon: "common/tick_grey.png",
          benefitText: "Personalized financial plan",
        },
        {
          benefitIcon: "common/tick_grey.png",
          benefitText: "Space to ask any question that’s top of mind",
        },
        {
          benefitIcon: "common/tick_grey.png",
          benefitText: "Reviewed by a real human expert",
        },
      ],
    },
  },
  {
    name: "Want more?",
    tag: <PremiumTag label="ALL ACCESS" />,
    actionButton: {
      label: "Vote for Premium",
      targetLink: `https://joinuprise.typeform.com/to/hDESCjcN?signupCode=${SANA_SIGNUP_CODE}`,
      width: "100%",
    },
    tierBenefits: {
      benefitTitle: (
        <SemiBoldText>
          Sana is exploring offering Uprise Premium to employees.
        </SemiBoldText>
      ),
      benefitInfos: [
        {
          benefitIcon: "common/tick_coral.png",
          benefitText: "Detailed advice on stock options and investments",
        },
        {
          benefitIcon: "common/tick_coral.png",
          benefitText: "Additional premium topics like real estate",
        },
        {
          benefitIcon: "common/tick_coral.png",
          benefitText: "Ongoing access to your advisor, anytime",
        },
        {
          benefitIcon: "common/tick_coral.png",
          benefitText: "Proactive monitoring for new optimizations ",
        },
      ],
    },
  },
];

export const FEEDBACK_DOUBLE_QUOTE_IMAGE_PATH =
  "home/feedback/feedback-double-quote.png";

export const WhatYouGetContent = [
  {
    title: "A personalized money plan",
    detailText:
      "Features a comprehensive look of your finances and actionable recommendations (a fan favorite).",
    logo_path: "v2.0/personalized-money-plan.png",
  },
  {
    title: "Unbiased advice from an expert",
    detailText:
      "Every plan is curated and all questions answered by a human advisor (CFP®), not a bot!",
    logo_path: "v2.0/heart-blackyellow.png",
  },
  {
    title: "Message your advisor anytime",
    detailText:
      "Your advisor is here for all your money questions, all year long.",
    logo_path: "v2.0/message-yellow.png",
  },
  {
    title: "Proactive monitoring + checkins",
    detailText:
      "We'll scan your finances for optimizations throughout the year and check in for updates!",
    logo_path: "v2.0/calendar-yellow.png",
  },
];

export const WhatYouGetAsPartnerContent = [
  {
    title: "Cross-sell more successfully.",
    detailText:
      "We surface your products to your users at the right moment and enable them to convert with one click.",
    logo_path: "partner/ic-valueProp1@2x.png",
  },
  {
    title: "Unlock a premium revenue stream.",
    detailText:
      "Our partners have been able to increase their revenue per user by 66%+.",
    logo_path: "partner/ic-valueProp2@2x.png",
  },
  {
    title: "See your customer's full financial picture.",
    detailText:
      "Get the full picture of your customer's finances (including assets held off your platform) to serve them better.",
    logo_path: "partner/ic-valueProp3@2x.png",
  },
  {
    title: "Skip months of regulatory overhead.",
    detailText:
      "It used to take months of regulator reviews and manpower to manage the ongoing compliance burden.",
    logo_path: "partner/ic-valueProp5@2x.png",
  },
];

export const WhatToDoAsNerdWalletContent = {
  title: "Know exactly what to do with your money",
  data: [
    {
      title: "Get a free personalized financial plan",
      detailText:
        "It’s free, easy, and fast to get a comprehensive financial plan you can take action on immediately.",
      logo_path: "partner/ic-valueProp9@2x.png",
    },
  ],
};

export const WhatYouGetAsNerdWalletContent = {
  title: "Then build your financial future by upgrading for...",
  data: [
    {
      title: "Unbiased advice from an expert",
      detailText:
        "You’ll get a Certified Financial Planner™, the highest level of credential in personal financial advising, who will craft a more detailed plan for you.",
      logo_path: "partner/ic-valueProp10@2x.png",
    },
    {
      title: "Unlimited messaging and plan updates",
      detailText:
        "Your advisor will respond to your questions all year long and work with you to keep your plan updated.",
      logo_path: "partner/ic-valueProp11@2x.png",
    },
    {
      title: "Proactive monitoring & check-ins",
      detailText:
        "We’ll scan for optimizations throughout the year and you can request an updated plan anytime.",
      logo_path: "partner/ic-valueProp12@2x.png",
    },
  ],
};

export const WhatYouGetAsNerdWalletSMBContent = {
  title: "Never worry about money again",
  data: [
    {
      title: "A comprehensive, personalized plan",
      detailText:
        "We’ll go through your finances to optimize them for your goals.",
      logo_path: "partner/ic-valueProp9@2x.png",
    },
    {
      title: "Unbiased advice from an expert",
      detailText:
        "You’ll work with an SMB expert Certified Financial Planner™, the highest level personal financial advisor.",
      logo_path: "partner/ic-valueProp10@2x.png",
    },
    {
      title: "Unlimited messaging and plan updates",
      detailText:
        "Your advisor will respond to your questions all year long and work with you to keep your plan updated.",
      logo_path: "partner/ic-valueProp11@2x.png",
    },
    {
      title: "Proactive monitoring & check-ins",
      detailText:
        "We’ll scan for optimizations throughout the year and will check in with you at least once a quarter.",
      logo_path: "partner/ic-valueProp12@2x.png",
    },
  ],
};

export const WhatYouGetAsPartnerRipplingContent = [
  {
    title: "Standout with a forward-thinking benefit",
    detailText:
      "Show your employees you care about their financial wellness with a benefit that stands out. Only the most progressive 25% of companies offer financial wellness.",
    logo_path: "partner/ic-valueProp1@2x.png",
  },
  {
    title: "Surprisingly affordable",
    detailText:
      "By leveraging automation and AI, you can offer your employees 10x advising experience at 1/10th the price.",
    logo_path: "partner/ic-valueProp3@2x.png",
  },
  {
    title: "Expertly guide your team on stock and benefits",
    detailText:
      "Your employees are coming to you for advice on their stock and benefits. Finally give them the help they deserve, any time they need it.",
    logo_path: "partner/ic-valueProp2@2x.png",
  },
  {
    title: "Answer HR questions that you can’t legally address",
    detailText:
      "Give your employees the support they need around their benefits and equity, while staying fully compliant with the law.",
    logo_path: "partner/ic-valueProp8@2x.png",
  },
  {
    title: "Bank-level security and integration",
    detailText:
      "Your employees and company’s information is secured with bank-level encryption and fully integrated with Rippling.",
    logo_path: "partner/ic-valueProp7@2x.png",
  },
  {
    title: "Increase benefit utilization",
    detailText:
      "We understand your benefits and equity. Let us help you explain your value to your employees, and help make the most of them.",
    logo_path: "partner/ic-valueProp6@2x.png",
  },
];

export const ExpertInPocketContent = [
  {
    left_image: "v2.0/uc-newparent-left.png",
    right_image: "v2.0/uc-newparent-right.png",
  },
  {
    left_image: "v2.0/uc-yuppie-left.png",
    right_image: "v2.0/uc-yuppie-right.png",
  },
  {
    left_image: "v2.0/uc-fire-left.png",
    right_image: "v2.0/uc-fire-right.png",
  },
];

export const ExpertInPocketContentNerdWalletSMB = [
  {
    left_image: "nerdwallet-smb/uc-beanie-left.png",
    right_image: "nerdwallet-smb/uc-beanie-right.png",
  },
  {
    left_image: "nerdwallet-smb/uc-curls-left.png",
    right_image: "nerdwallet-smb/uc-curls-right.png",
  },
  {
    left_image: "nerdwallet-smb/uc-long-left.png",
    right_image: "nerdwallet-smb/uc-long-right.png",
  },
];

export const VideoCardContent = [
  {
    number: "01",
    header: "We get to know you",
    details:
      "Your questions, goals and current money situation. It all matters!",
  },
  {
    number: "02",
    header: "Your advisor drafts your money plan",
    details:
      "It'll include answers to all your questions and actionable recommendations around how to make your money work harder for you!",
  },
  {
    number: "03",
    header: "We'll keep revising",
    details: "...until we've gotten it just right for you.",
  },
];

export const OchoVideoCardContent = [
  {
    number: "01",
    header: "We get to know you and your business!",
    details:
      "Your questions, goals and current money situation. It all matters!",
  },
  {
    number: "02",
    header: "Your advisor drafts your money plan",
    details:
      "It'll include answers to all your questions and actionable recommendations around how to make your money work harder for you!",
  },
  {
    number: "03",
    header: "We'll keep revising",
    details: "...until we've gotten it just right for you.",
  },
];

export const CoreProductTopics = [
  {
    title: "Credit, debt, emergency savings",
    core: true,
    allAccess: true,
  },
  {
    title: "Goals and budgeting",
    core: true,
    allAccess: true,
  },
  {
    title: "Allocation: debt vs cash vs investing, etc",
    core: true,
    allAccess: true,
  },
  {
    title: "Retirement: how much to save and where",
    core: true,
    allAccess: true,
  },
  {
    title: "Getting started with investing",
    core: true,
    allAccess: true,
  },
  {
    title: "Company equity best practices",
    core: true,
    allAccess: true,
  },
  {
    title: "The best cash accounts and credit cards",
    core: true,
    allAccess: true,
  },
  {
    title: "Managing side-income taxes",
    core: true,
    allAccess: true,
  },
  {
    title: "Salary benchmarking",
    core: true,
    allAccess: true,
  },
  {
    title: "Life insurance, wills, estate planning",
    core: true,
    allAccess: true,
  },
];

export const AllAccessProductTopics = [
  {
    title: "Specific investment advice",
    helper: "Like buy AAA, sell BBB, what to do with your RSUs and ESPP",
    core: false,
    allAccess: true,
  },
  {
    title: "Specific company equity advice",
    helper: "Like whether to exercise, how much to exercise, and when",
    core: false,
    allAccess: true,
  },
];

export const TopicsWeCoverFAQByAllProductData = [
  ...CoreProductTopics,
  ...AllAccessProductTopics,
];

export const HowUpriseWorksContent = {
  title: "How it works",
  helperTextSteps: [
    "No high prices",
    "No limited availability",
    "No old school approach",
  ],
  contentSteps: [
    {
      title: "We get to know you, and create a plan for you for free.",
      content:
        "We cover everything, even your employer benefits, because the details matter. You’ll receive an initial financial plan immediately.",
    },
    {
      title: "Upgrade to work with a financial advisor.",
      content:
        "They’ll go through, ask follow-up questions, and draft a more detailed plan that will include answers to all your top questions along with specific, actionable recommendations.",
    },
    {
      title: "Stay on track.",
      content:
        "We monitor your finances for you, continually surfacing new opportunities, and can updated your plan whenever you want.",
    },
    {
      title: "We’re here for you.",
      content: "Message your advisor any time, as much as you want.",
    },
  ],
};

export const HowNerdWalletSMBWorksContent = {
  title: "How it works",
  helperTextSteps: [
    "No high prices",
    "No limited availability",
    "No old school approach",
  ],
  contentSteps: [
    {
      title: "We get to know you",
      content:
        "We cover everything about your business and also about your personal finances. Because both matter.",
      free: true,
    },
    {
      title: "We give you immediately actionable advice",
      content:
        "Ask us anything on your call - cash flow, taxes, entity formation - we’ll point you in the right direction.",
      free: true,
    },
    {
      title: "Get a comprehensive plan",
      content:
        "We’ll dive deeper on your finances to spot optimizations and surface additional recommendations. And we’ll check in with you regularly to make sure your plan stays relevant.",
    },
    {
      title: "We're here for you",
      content:
        "Message your advisor any time, as much as you want. Or add-on a video call for an additional fee when you need it. ",
    },
  ],
};

export const WhatWeCoverContent = {
  left: [
    "Minimizing taxes",
    "Saving and investing for your goals",
    "Creating a budget that works for you",
    "Paying off debt",
    "Getting married or divorced",
    "Having a baby or adopting",
    "Saving and investing for your children",
  ],
  right: [
    "Buying or investing in a home",
    "Supporting aging parents",
    "Planning for retirement",
    "Taking advantage of your company equity",
    "Ensuring adequate insurance coverage",
    "Finding the best cards that fit your lifestyle",
  ],
};

export const NERDWALLET_COACH_POWERED_BY_UPRISE =
  "NerdWallet Coach, powered by Uprise";

export const NerdwalletFaqData = [
  {
    question: `What can I expect from you?`,
    questionKey: "expectation",
    id: "faq-expectation",
    answer: {
      secondary:
        "Once you sign up, you can answer a series of questions to make sure we understand what you care most about and your financial situation. It’s comprehensive but it should only take 15 minutes. You’ll immediately get an initial plan based on your answers from our system, which has the experience of thousands of situations like yours. \n\n When you upgrade, you’ll be assigned the Certified Financial Planner™ that is the best match for you. They’ll craft a more detailed, comprehensive financial plan for you. You can message your advisor any time, and they’ll work with you to make sure your plan is spot on. You’ll have a dashboard that you can use to monitor your net worth and progress toward your goals. At the same time, we’ll be continually scanning your finances for new opportunities to optimize. You can request an updated financial plan any time.",
    },
    isExpanded: false,
  },
  {
    question: "Do you serve people outside the US?",
    questionKey: "service-location",
    id: "faq-service-location",
    answer: {
      secondary:
        "Sorry, we’re only able to serve people within the U.S. at the moment.",
    },
    isExpanded: false,
  },
  {
    question: "How are you able to make this so affordable?",
    questionKey: "affordability",
    id: "faq-affordability",
    answer: {
      primary: "",
      secondary:
        "In short, technology! We’re able to offer expert financial advising at a price that’s more accessible by combining Certified Financial Planners™ with the best in AI and our database of financial rules and products. Think of us like a doctor backed by a massive database of all the symptoms and cures, but for your money.",
    },
    isExpanded: false,
  },
  {
    question: "Who is this for?",
    questionKey: "target-customer",
    id: "faq-target-customer",
    answer: {
      primary: "",
      secondary:
        "We’re great for anyone who has an extra dollar, and they’re not sure where to put it, whether to pay down debt, or invest it (and if so, where). We’re not best for people who are in retirement or close to it, since our system is optimized for the wealth building phase of life.",
    },
    isExpanded: false,
  },
  {
    question: "Is there anything you can’t advise on?",
    questionKey: "advice-boundary",
    id: "faq-advice-boundary",
    answer: {
      secondary:
        "We can advise on the full spectrum of your financial life. Right now though, we can’t offer buy/sell advice on specific securities (like specific stocks or stock options), though we can provide general advice on your investments like diversification best practices.",
    },
    isExpanded: false,
  },
  {
    question: `What is ${NERDWALLET_COACH_POWERED_BY_UPRISE}?`,
    questionKey: "what-is",
    id: "faq-what-is",
    answer: {
      secondary:
        "NerdWallet is partnering with Uprise to offer the most accessible, tech-forward financial advising out there. Your advisors are US-based Certified Financial Planners™, the highest qualification in financial planning, and they’re backed by the best in AI and a comprehensive database of financial rules and products.                                                                                                                                                                    ",
    },
    isExpanded: false,
  },
  {
    question: `How does the money-back guarantee work?`,
    questionKey: "money-back",
    id: "faq-money-back",
    answer: {
      secondary: (
        <div>
          It’s really simple. If you’re not happy with what you get from us, you
          can get a full refund by emailing us within 30 days at{" "}
          <UpriseEmailLink
            style={{ display: "inline", wordBreak: "break-all" }}
            label={NERDWALLET_COACH_HELLO_EMAIL}
          />
        </div>
      ),
    },
    isExpanded: false,
  },
];

export const NerdwalletSMBFaqData = [
  {
    question: `What can I expect from you?`,
    questionKey: "expectation",
    id: "faq-expectation",
    answer: {
      secondary: (
        <div>
          {
            "Book a free 45-min call with a Certified Financial PlannerTM small business expert here. You’ll get unbiased advice personalized to your business and/or your personal finances, as well as a follow-up note from your advisor with a summary of action items from your conversation."
          }
          <br />
          <br />
          {
            "If you want to get ongoing advice, you’ll be given a link to sign up for the platform. You’ll answer a series of questions to make sure we understand what you and your business’s financial situation. It’s comprehensive but it should only take 20 minutes."
          }
          <br />
          <br />
          {
            "Your advisor will then craft a full financial plan for you with the aid of our system, which has the experience of thousands of situations like yours. You can message your advisor any time, and they’ll work with you to make sure your plan is spot on. You’ll have a dashboard that you can use to monitor your net worth and progress toward your goals. At the same time, we’ll be continually scanning your finances for new opportunities to optimize, and we can fully update your plan any time you’d like."
          }
        </div>
      ),
    },
    isExpanded: false,
  },
  {
    question: "Do you serve people outside the US?",
    questionKey: "service-location",
    id: "faq-service-location",
    answer: {
      secondary:
        "Sorry, we’re only able to serve people within the U.S. at the moment.",
    },
    isExpanded: false,
  },
  {
    question: "How are you able to make this so affordable?",
    questionKey: "affordability",
    id: "faq-affordability",
    answer: {
      primary: "",
      secondary:
        "In short, technology! We’re able to offer expert financial advising at a price that’s more accessible by combining Certified Financial Planners™ with the best in AI and our database of financial rules and products. Think of us like a doctor backed by a massive database of all the symptoms and cures, but for your money.",
    },
    isExpanded: false,
  },
  {
    question: "Who is this for?",
    questionKey: "target-customer",
    id: "faq-target-customer",
    answer: {
      primary: "",
      secondary:
        "We’re great for any small business owner that has some revenue, regardless of number of employees or incorporation status. We’re not best for people who are still thinking of starting a small business, but don’t have any revenue yet (though we’re cheering you on!).",
    },
    isExpanded: false,
  },
  {
    question: "Is there anything you can't advise on?",
    questionKey: "advice-boundary",
    id: "faq-advice-boundary",
    answer: {
      secondary: (
        <>
          {
            "We can advise on the full spectrum of your business and your personal finances. There’s a few things we can’t do yet though:"
          }
          <br />
          <br />
          <ul>
            <li>
              {
                "We can’t do your bookkeeping or accounting for you, though we’ll connect you to great options."
              }
            </li>
            <li>
              {
                "We also can’t file your taxes for you, though we can help you with tax planning and optimization, and we’ll, again, connect you to great options."
              }
            </li>
            <li>
              {
                "We can’t offer buy/sell advice on specific securities (like specific stocks or stock options), though we can provide general advice on your investments like diversification best practices."
              }
            </li>
          </ul>
        </>
      ),
    },
    isExpanded: false,
  },
  {
    question: `What is ${NERDWALLET_COACH_POWERED_BY_UPRISE}?`,
    questionKey: "what-is",
    id: "faq-what-is",
    answer: {
      secondary:
        "NerdWallet is partnering with Uprise to offer the most accessible, tech-forward financial advising out there for small business owners. Your advisors are specialists in small businesses and are US-based Certified Financial Planners™, the highest qualification in financial planning. And they’re backed by the best in AI and a comprehensive database of financial rules and products.",
    },
    isExpanded: false,
  },
  {
    question: `How does the money-back guarantee work?`,
    questionKey: "money-back",
    id: "faq-money-back",
    answer: {
      secondary: (
        <div>
          It’s really simple. If you’re not happy with what you get from us, you
          can get a full refund by emailing us within 30 days at{" "}
          <UpriseEmailLink
            style={{ display: "inline", wordBreak: "break-all" }}
            label={NERDWALLET_COACH_SMB_HELLO_EMAIL}
          />
          .
        </div>
      ),
    },
    isExpanded: false,
  },
];
