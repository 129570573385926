/* eslint-disable no-underscore-dangle */
import React, { forwardRef } from "react";
import styled from "styled-components";
import { BODY_LINK_TYPE } from "../../utils/commonConst";
import { Colors, FontSizes, FontWeights } from "../../styles/styleConstants";
import { isEmpty, isNonEmpty } from "../../utils/commonUtil";

export const LINK_TARGET = {
  BLANK: "_blank",
  SELF: "_self",
  PARENT: "_parent",
  TOP: "_top",
};

function UpriseLink(props, ref) {
  const {
    href,
    onClick,
    size = BODY_LINK_TYPE.BODY_LINK_SMALL,
    children,
    externalLink,
    linkColor,
    style,
    disabled = false,
    target = LINK_TARGET.SELF,
  } = props || {};
  const _onClick = (event) => {
    if (onClick != null) {
      onClick();
    }
    if (isNonEmpty(externalLink)) {
      window.open(externalLink);
    }
    event.stopPropagation();
  };

  const renderLinkBody = () => {
    return (
      <LinkBody
        onClick={(event) => {
          _onClick(event);
          event.stopPropagation();
        }}
        size={size}
        linkColor={linkColor}
        disabled={disabled}
        ref={ref}
      >
        {children}
      </LinkBody>
    );
  };
  return (
    <LinkWrapper style={style}>
      {isEmpty(href) ? (
        renderLinkBody()
      ) : (
        <Link href={href} ref={ref} target={target}>
          {renderLinkBody()}
        </Link>
      )}
    </LinkWrapper>
  );
}

export default forwardRef(UpriseLink);

const LinkWrapper = styled.div`
  display: inline-block;
  ${(props) =>
    props.style && props.style.margin ? props.style.margin : "margin: 0px 8px"};
  ${(props) => (props.style && props.style.width ? props.style.width : "")};
`;

// const LinkBody = styled.div`
//   font-style: normal;
//   font-weight: 600;
//   font-size: ${(props) => (props.size === LINK_SIZE.SMALL ? "16px" : "20px")};
//   line-height: ${(props) => (props.size === LINK_SIZE.SMALL ? "24px" : "30px")};
//   color: ${(props) => (props.linkColor ? props.linkColor : Colors.blue)};
// `;

const LinkBody = styled.div`
  ${(props) => {
    switch (props.size) {
      case BODY_LINK_TYPE.BODY_LINK_SMALL: {
        return `
        font-style: normal;
        font-weight: ${FontWeights.bold}};
        font-size: ${FontSizes.md};
        line-height: 24px;
        color: ${Colors.almostBlack};
        color: ${props.linkColor ? props.linkColor : Colors.almostBlack};
        `;
      }
      case BODY_LINK_TYPE.BODY_LINK_SMALLEST: {
        return `
        font-style: normal;
        font-weight: ${FontWeights.bold}};
        font-size: ${FontSizes.sm};
        line-height: 24px;
        color: ${Colors.almostBlack};
        color: ${props.linkColor ? props.linkColor : Colors.almostBlack};
        `;
      }
      default: {
        return `
      font-style: normal;
      font-weight: ${FontWeights.bolder}};
      font-size: ${FontSizes.default};
      line-height: 24px;
      color: ${props.linkColor ? props.linkColor : Colors.blue};
      `;
      }
    }
  }}
  ${(props) =>
    props.disabled === false
      ? `
  cursor: pointer;
  :hover {
    text-decoration-line: underline;
  }
  `
      : `
  cursor: default;
  `}
`;

const Link = styled.a``;
