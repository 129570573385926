import React from "react";
import styled from "styled-components";

export default function SemiBoldText(props) {
  const { children } = props ?? {};
  return <SemiBold>{children}</SemiBold>;
}

const SemiBold = styled.b`
  font-weight: 600;
`;
