import { ContactEmail } from "../../data/commonData";
import React, { forwardRef } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/styleConstants";
import UpriseLink from "../common/UpriseLink";

function UpriseEmailLink(props, ref) {
  const {
    style = {},
    linkColor = Colors.almostBlack,
    label = "Email us",
  } = props ?? {};
  return (
    <EmailLinkTextContainer style={style}>
      <UpriseLink
        linkColor={linkColor}
        href={`mailto:${ContactEmail}`}
        style={{ margin: "0px 0px" }}
      >
        {label}
      </UpriseLink>
    </EmailLinkTextContainer>
  );
}

const EmailLinkTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${(props) =>
    !props.isIconVisible &&
    "align-items: baseline !important; margin: 0 !important;"}
`;

const EmailIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export default forwardRef(UpriseEmailLink);
