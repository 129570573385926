/* eslint-disable no-underscore-dangle */
import React from "react";
import styled from "styled-components";
// import PropTypes from "prop-types";
import { Colors } from "../../styles/styleConstants";
import CrownImage from "../common/CrownImage";
import Tag from "../common/Tag";

function PremiumTag(props) {
  const { label = "PREMIUM", style } = props ?? {};
  return (
    <>
      <PremiumTagWrapper
        onClick={(evt) => {
          // router.push(PAGE_PATH.PREMIUM);
          evt.preventDefault();
          evt.stopPropagation();
        }}
        style={style}
      >
        <CrownImage width="16px" height="16px" />
        {label}
      </PremiumTagWrapper>
    </>
  );
}

// PremiumTag.propTypes = {};

export default PremiumTag;

const PremiumTagWrapper = styled(Tag)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;

  height: 24px;
  background: linear-gradient(
      0deg,
      rgba(251, 111, 140, 0.15),
      rgba(251, 111, 140, 0.15)
    ),
    #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: ${Colors.coral};
  cursor: default;
`;
