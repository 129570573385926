/* eslint-disable no-underscore-dangle */
import React from "react";
import styled from "styled-components";
// import PropTypes from "prop-types";
import { Colors } from "../../styles/styleConstants";
import Tag from "../common/Tag";

function FreeTag(props) {
  const { label = "FREE", style = {} } = props ?? {};
  return (
    <>
      <FreeTagWrapper
        onClick={(evt) => {
          // router.push(PAGE_PATH.Free);
          evt.preventDefault();
          evt.stopPropagation();
        }}
        style={style}
      >
        {label}
      </FreeTagWrapper>
    </>
  );
}

// FreeTag.propTypes = {};

export default FreeTag;

const FreeTagWrapper = styled(Tag)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
  gap: 4px;

  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  color: ${Colors.neutralDark};
  background: ${Colors.neutralMedium};
  border-radius: 17px;
  cursor: default;
`;
