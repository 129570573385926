/* eslint-disable no-underscore-dangle */
import React from "react";
import styled from "styled-components";
import { Colors } from "../../styles/styleConstants";
import Image from "./Image";

function CrownImage(props) {
  const {
    style = {},
    width = "24px",
    height = "24px",
    color = Colors.coral,
  } = props ?? {};
  return (
    <Image
      src="premium/crown_coral.png"
      style={{
        width: "24px",
        height: "24px",
        display: "inline-block",
      }}
      imgStyle={{ objectFit: "contain" }}
    />
  );
}

export default CrownImage;

const CrownImageWrapper = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
