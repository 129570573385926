/* eslint-disable no-underscore-dangle */
import React from "react";
import styled from "styled-components";
import { Colors } from "../../styles/styleConstants";

function Tag(props) {
  const { children, className, style, onClick } = props || {};
  return (
    <TagWrapper className={className} onClick={onClick} style={style}>
      {children}
    </TagWrapper>
  );
}

export default Tag;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 24px;

  background: ${Colors.blue60};
  border-radius: 30px;
`;
