import React from "react";
import styled from "styled-components";
import { Colors, FontFamilies } from "../../styles/styleConstants";

function HelperText(props) {
  const { children, style, className, margin } = props || {};
  return (
    <BodyTextWrapper margin={margin} className={className}>
      <Text style={style}>{children}</Text>
    </BodyTextWrapper>
  );
}

export default HelperText;

const BodyTextWrapper = styled.div`
  margin: ${(props) => (props.margin ? props.margin : "8px 0px")};
`;

const Text = styled.div`
  font-family: ${FontFamilies.base};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${(props) =>
    props?.style?.color ? props?.style?.color : Colors.almostBlack};
`;
